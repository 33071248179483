import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Darin from '../../assets/imgs/employees/darin.jpg';
import Tim from '../../assets/imgs/employees/tim.jpg';
import TerritoriesPDF from '../../assets/pdfs/70015 - TapsCO2 Sales Territories.pdf';

const Employees = [
  {
    img: Darin,
    name: 'Darin Morales',
    title: 'Salesman'
  },
  {
    img: Tim,
    name: 'Tim Buffuna',
    title: 'Salesman'
  },
]

function EmployeeList() {
  return (
    <Box sx={{ color: "white", backgroundColor: "secondary.main", padding: "50px 0" }}>
      <Container>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} textAlign="center" sx={{ paddingBottom: "25px" }}>
            <Typography variant="h2">Our Team</Typography>
          </Grid>
          {Employees.map((employee, i) => (
            <Grid item xs={12} sm={4} key={i}>
              <Box sx={{ position: "relative", display: "flex" }}>
                <img src={employee.img} alt={employee.name} width="100%"/>
                <Box 
                  sx={{ 
                    position: "absolute", 
                    display: "flex",
                    bottom: "0px", 
                    left: "0px",
                    right: "0px",
                    background: "rgba(0, 0, 0, 0.5) none repeat scroll 0% 0%",
                    padding: "5px"
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h5">{employee.name}</Typography>
                    <Typography>{employee.title}</Typography>
                  </Box>
                  <IconButton sx={{ color: "white" }} component="a" href={TerritoriesPDF} target="_blank">
                    <InfoIcon />
                  </IconButton>
                </Box>              
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default EmployeeList;