import { ThemeProvider } from '@mui/material/styles';

//route pages
import Routes from './routes';

//mui theme for styles and palette
import theme from './themes';

import PageTracking from './components/PageTracking';
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
import Nav from './components/Nav';
import Footer from './components/Footer'

function App() {
	return (
		<ThemeProvider theme={theme}>
			<PageTracking />
			<ScrollToTop />
			<ScrollToTopButton />
			<Nav />
			<Routes />
			<Footer />
		</ThemeProvider>
	);
}

export default App;