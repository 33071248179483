import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import HeroVideo from '../../assets/videos/home-hero-dark.mp4';

function Hero() {
  return (
    <Box position="relative">
      <Container sx={{ position: "absolute", right: 0, left: 0, top: "25%"}}>
        <Grid container>
          <Grid item xs={12} sm={8} md={4}>
            <Typography variant="h2" color="secondary.main">Delivering high quality</Typography>
            <Divider sx={{ borderColor: "white", borderBottomWidth: "8px", margin: "25px 0", width: "125px" }} />
            <Typography variant="h1" color="primary.main">CO<sub>2</sub></Typography>
          </Grid>
        </Grid>
      </Container>
      <video width="100%" height="776px" preload="auto" playsInline autoPlay muted loop style={{ objectFit: "cover" }}>
        <source src={HeroVideo} type="video/mp4" />
        Your browser does not support the video.
      </video>
    </Box>
  )
}

export default Hero;