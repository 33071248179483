import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Container, Grid, TextField, Typography  } from '@mui/material';
import { styled } from '@mui/material/styles';
import LocationsList from './LocationsList';

const LocationAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'black',
    '& fieldset': {
      borderColor: 'black',
    }
  },
  '& label': {
    color: 'black',
  },
}));

function Locations() {
  const [value, setValue] = React.useState(LocationsList[0]);
  const [locationValue, setLocationValue] = React.useState("0");
  const searchParams = new URLSearchParams(document.location.search);
  const history = useNavigate();
  
  console.log(history)

  React.useEffect(() => {
      if (searchParams.get('location') != null && searchParams.get('location') <= LocationsList.length && Number.isInteger(parseInt(searchParams.get('location')))) {
          setValue(LocationsList[searchParams.get('location')]);
          setLocationValue(searchParams.get('location'));
      }
  // eslint-disable-next-line
  }, [useNavigate()]);

  function handleSearchChange(e) {
      setValue(e);
      if (e != null)
      {
          setLocationValue(LocationsList.findIndex(f => f.location === e.location));
          searchParams.set('location', LocationsList.findIndex(f => f.location === e.location));
      } else {
          setLocationValue("0");
          searchParams.set('location', "");
      }
      history(window.location.pathname + "?" + searchParams.toString());
  };

  return (
    <Container>
      <Grid container>
        <Grid 
          item 
          xs={12} 
          id="search"
        >
          <Typography variant="h3">Search for a specific location!</Typography>
          <LocationAutocomplete
              disablePortal
              options={LocationsList}
              getOptionLabel={(option) => option.location}
              value={value}
              onChange={(event, newValue) => {
                  handleSearchChange(newValue);
              }}
              sx={{ width: '100%', padding: "25px 0" }}
              renderInput={(params) => <TextField {...params} label="Locations" />}
          />
          <Box sx={{ margin: "0 auto"}}>
              <Typography variant="h3">{LocationsList[locationValue].location}</Typography>
              <Typography variant="h4">{LocationsList[locationValue].address} {LocationsList[locationValue].zip}</Typography>
              <Typography variant="h5" className="unbold"><b>Phone:</b> {LocationsList[locationValue].phone}</Typography>
              <Typography variant="h5" className="unbold"><b>Fax:</b> {LocationsList[locationValue].fax}</Typography>
              <Typography variant="h5" className="unbold"><b>Monday-Friday Hours:</b> {LocationsList[locationValue].hoursMF}</Typography>
              <Typography variant="h5" className="unbold"><b>Saturday Hours:</b> {LocationsList[locationValue].hoursSAT}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Locations;