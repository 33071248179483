import { Grid, Typography } from '@mui/material';
import Action from '../../assets/imgs/home-action.JPG';
import CheckIcon from '@mui/icons-material/Check';

const WhyCards = [
  {
    title: 'Reliable',
    bgColor: 'primary.main'
  },
  {
    title: 'Responsive',
    bgColor: 'secondary.main'
  },
  {
    title: 'Local',
    bgColor: 'background.main'
  },
];

function Highlights() {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ position: "relative", textAlign: "center" }}>
        <img 
          src={Action} 
          alt="action shot of installment" 
          style={{ 
            width: "100%", 
            height: "600px",
            objectFit: "cover",
            display: "block"
          }} 
        />
        <Typography
          variant="h2"
          color="white"
          sx={{ 
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          Installers you can trust.
        </Typography>
      </Grid>
      <Grid container>
        {WhyCards.map((card, i) => (
          <Grid 
            item 
            xs={12} 
            sm={4} 
            key={i} 
            sx={{ 
              textAlign: "center", 
              padding: "50px 0", 
              backgroundColor: card.bgColor,
              color: "white"
            }}
          >
            <CheckIcon sx={{ fontSize: "2rem" }} />
            <Typography variant="h4">{card.title}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Highlights;