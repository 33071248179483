import { Link } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@mui/material';
import Salesman from '../../assets/imgs/home-about.JPG';

function WhyUs() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6} sx={{ margin: "50px 0"}}>
        <Container maxWidth="sm">
          <Typography variant="h2" color="primary">Why Us?</Typography>
          <Typography sx={{ padding: "25px 0" }}>Our TapsCO2 Division focuses on setting customers up with a CO2 System that cuts costs and maximizes efficiency. We also offer on-site service and technical support in a timely manner because we are a local supplier.</Typography>
          <Button variant="contained" component={Link} to="/about">Learn More</Button>
        </Container>
      </Grid>
      <Grid item xs={12} md={6}>
        <img 
          src={Salesman} 
          alt="Salesman" 
          style={{ 
            width: "100%", 
            height: "600px",
            objectFit: "cover",
            display: "block"
          }}
        />
      </Grid>
    </Grid>
  )
}

export default WhyUs;