import { useMediaQuery } from '@mui/material';
import NavDesktop from './NavDesktop';
import NavMobile from './NavMobile';

function Nav() {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
      isDesktop ? (
          <NavDesktop />
      ) : (
          <NavMobile />
      )
  )
}

export default Nav;