import { Link } from 'react-router-dom';
import { AppBar, Button, Toolbar } from '@mui/material';
import MainRoutes from '../../routes/MainRoutes';
import Logo from '../../assets/imgs/svgs/tapsco2-logo.svg';

function NavDesktop() {
  return (
    <AppBar position="static" color="background">
      <Toolbar id="back-to-top-anchor">
        <div style={{ display: "flex", flexGrow: 1, alignContent: "center" }}>
          <Link to="/">
            <img 
              src={Logo} 
              alt="TapsCO2 Logo" 
              height="40px"
            />
          </Link>
        </div>
        {MainRoutes.map((route, i) => (
          <Button
            key={i}
            component={Link}
            to={route.path}
          >
            {route.name}
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  )
}

export default NavDesktop;