import { Box, Typography } from "@mui/material";

function Hero(props) {
  return (
    <Box 
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "400px",
        backgroundColor: "primary.main",
        color: "white"
      }}   
    >
      <Typography variant="h2">{props.title}</Typography>
    </Box>
  )
}

export default Hero;