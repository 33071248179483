import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemText, Toolbar } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import MainRoutes from '../../routes/MainRoutes';
import Logo from '../../assets/imgs/svgs/tapsco2-logo.svg';

function NavMobile() {
  const [drawer, setDrawer] = React.useState(false);

  const drawerToggle = () => {
   setDrawer(!drawer);
  }

  return (
    <AppBar position="absolute" color="background">
      <Toolbar
        sx={{ '&::after': { flex: 1, content: '""' } }}
        id="back-to-top-anchor"
      >
        <div style={{ flex: 1 }}>
          <IconButton
            onClick={drawerToggle}
            size="large"
            color="primary"
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Link to="/">
          <img 
            src={Logo} 
            alt="TapsCO2 Logo" 
            height="40px"
          />
        </Link>
        <Drawer
          anchor="left"
          open={drawer}
          onClose={drawerToggle}
          PaperProps={{ 
            sx: { 
              bgcolor: "background.main", 
              color: "primary.main" 
            } 
          }}
        >
          <Box
            sx={{ width: 250 }}
            onClick={drawerToggle}
          >
            <List>
              {MainRoutes.map((route, i) => (
                <ListItem
                  key={i}
                  component={Link}
                  to={route.path}
                  button
                >
                  <ListItemText primary={route.name} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  )
}

export default NavMobile;