function Map() {
  return (
    <iframe 
      title="TapsCO2 Service Area Google Map"
      src="https://www.google.com/maps/d/embed?mid=1_fFbM_Bo4UkTT9nJkzpTkwhT5evDxQj7&ehbc=2E312F" 
      width="100%" 
      height="500px"
      frameBorder="0"
      style={{
        display: "block"
      }}
    />
  )
}

export default Map;