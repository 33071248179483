import Helmet from 'react-helmet';

function PageHelmet(props) {
  return(
    <Helmet>
      <title>{props.title} - TapsCO2</title>
    </Helmet>
  )
}

export default PageHelmet;