import { Grid } from '@mui/material';
import Hero from '../../components/Hero';
import WhoWeAre from './WhoWeAre';
import EmployeeList from './EmployeeList';
import PageHelmet from '../../components/PageHelmet';

function About() {
  return (
    <Grid container>
      <PageHelmet title="About" />
      <Grid item xs={12}>
        <Hero title="About Us" />
      </Grid>
      <Grid item xs={12}>
        <WhoWeAre />
      </Grid>
      <Grid item xs={12}>
        <EmployeeList />
      </Grid>
    </Grid>
  )
}

export default About;