import { Container, Grid, Typography } from '@mui/material';
import Salesman from '../../assets/imgs/home-about.JPG';

function WhoWeAre() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6} sx={{ margin: "50px 0"}}>
        <Container maxWidth="sm">
          <Typography variant="h2" color="primary">For the Perfect Pour</Typography>
          <Typography sx={{ padding: "25px 0" }}>TapsCO2 is a division of Fresno Oxygen & Welding Suppliers, Inc. TapsCO2  operates from within our Barnes Welding Supply retail locations. We have 12 stores doing business as Barnes Welding Supply. All these stores are strategically located in major industrial hubs along Highway 99 from Bakersfield to Sacramento. We also have 1 location in Salinas to service the central coast. Supporting our stores and our customers we operate four industrial Gas Fill Plants, one Specialty Gas Lab and a Central Distribution Warehouse.</Typography>
        </Container>
      </Grid>
      <Grid item xs={12} md={6}>
        <img 
          src={Salesman} 
          alt="Salesman" 
          style={{ 
            width: "100%", 
            height: "600px",
            objectFit: "cover",
            display: "block"
          }}
        />
      </Grid>
    </Grid>
  )
}

export default WhoWeAre;