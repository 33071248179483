import { HashLink } from 'react-router-hash-link'
import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import MailIcon from '@mui/icons-material/Mail'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'

const ContactList = [
  {
    icon: <LocationOnIcon />,
    pText: 'Address',
    sText: '2825 S. Elm Ave #101 Fresno, CA 93706',
  },
  {
    icon: <PhoneIcon />,
    pText: 'Phone',
    sText: '(833) 827-7262',
  },
  {
    icon: <MailIcon />,
    pText: 'Email',
    sText: 'info@tapsco2.com',
  },
]

const MediaList = [
  {
    icon: <InstagramIcon />,
    pText: '@tapsco2',
    link: 'https://www.instagram.com/tapsco2/',
  },
  {
    icon: <FacebookIcon />,
    pText: 'TapsCO2',
    link: 'https://www.facebook.com/TapsCO2/',
  },
  {
    icon: <YouTubeIcon />,
    pText: '@tapsco2',
    link: 'https://www.youtube.com/@tapsco2/',
  },
]

function Footer() {
  return (
    <Box bgcolor='background.main' padding='25px 0'>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h5' color='primary'>
              Contact Us
            </Typography>
            <List>
              {ContactList.map((item, i) => (
                <ListItem key={i}>
                  <ListItemAvatar sx={{ color: 'primary.main' }}>
                    {item.icon}
                  </ListItemAvatar>
                  <ListItemText primary={item.pText} secondary={item.sText} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h5' color='primary'>
              Connect with Us
            </Typography>
            <List>
              {MediaList.map((item, i) => (
                <a
                  href={item.link}
                  target='_blank'
                  rel='noreferrer noopener'
                  key={i}
                >
                  <ListItem>
                    <ListItemAvatar sx={{ color: 'primary.main' }}>
                      {item.icon}
                    </ListItemAvatar>
                    <ListItemText primary={item.pText} />
                  </ListItem>
                </a>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h5' color='primary' paddingBottom='16px'>
              12 Locations to Serve You
            </Typography>
            <Stack
              direction='row'
              spacing={2}
              divider={<Typography color={'#95CBEE'}>●</Typography>}
              className='list-row'
            >
              <Typography
                component={HashLink}
                to='/contact?location=1#search'
                color='primary'
              >
                Fresno
              </Typography>
              <Typography
                component={HashLink}
                to='/contact?location=7#search'
                color='primary'
              >
                Bakersfield
              </Typography>
              <Typography
                component={HashLink}
                to='/contact?location=4#search'
                color='primary'
              >
                Clovis
              </Typography>
            </Stack>
            <Stack
              direction='row'
              spacing={2}
              divider={<Typography color={'#95CBEE'}>●</Typography>}
              className='list-row'
            >
              <Typography
                component={HashLink}
                to='/contact?location=6#search'
                color='primary'
              >
                Citrus Heights
              </Typography>
              <Typography
                component={HashLink}
                to='/contact?location=5#search'
                color='primary'
              >
                Madera
              </Typography>
              <Typography
                component={HashLink}
                to='/contact?location=9#search'
                color='primary'
              >
                Modesto
              </Typography>
            </Stack>
            <Stack
              direction='row'
              spacing={2}
              divider={<Typography color={'#95CBEE'}>●</Typography>}
              className='list-row'
            >
              <Typography
                component={HashLink}
                to='/contact?location=3#search'
                color='primary'
              >
                Porterville
              </Typography>
              <Typography
                component={HashLink}
                to='/contact?location=8#search'
                color='primary'
              >
                Sacramento
              </Typography>
              <Typography
                component={HashLink}
                to='/contact?location=12#search'
                color='primary'
              >
                Salinas
              </Typography>
            </Stack>
            <Stack
              direction='row'
              spacing={2}
              divider={<Typography color={'#95CBEE'}>●</Typography>}
              className='list-row'
            >
              <Typography
                component={HashLink}
                to='/contact?location=10#search'
                color='primary'
              >
                Stockton
              </Typography>
              <Typography
                component={HashLink}
                to='/contact?location=11#search'
                color='primary'
              >
                Turlock
              </Typography>
              <Typography
                component={HashLink}
                to='/contact?location=2#search'
                color='primary'
              >
                Visalia
              </Typography>
            </Stack>
            <Stack
              direction='row'
              spacing={2}
              divider={<Typography color={'#95CBEE'}>●</Typography>}
              className='list-row'
            >
              <Typography
                component={HashLink}
                to='/contact?location=14#search'
                color='primary'
              >
                West Sacramento
              </Typography>
            </Stack>
            <Divider sx={{ borderColor: 'white', margin: '5px 0' }} />
            <Typography color='secondary'>
              &copy; {new Date().getFullYear()} Fresno Oxygen. All Rights
              Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
