import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';


function ContactForm() {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [token, setToken] = useState('');
  const [errors, setErrors] = useState({});

  const validate = () => {
      let temp = {...errors};
      if ('name' in formValues)
          temp.name = formValues.name ? '' : 'Please enter your name.';
      if ('email' in formValues) 
          temp.email = formValues.email && /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(formValues.email) ? '' : 'Please enter a valid email address.';
      if ('message' in formValues)
          temp.message = formValues.message ? '' : 'Please enter a message.';

      setErrors({
          ...temp,
      })

      if (formValues)
          return Object.values(temp).every((x) => x === '');
  };
  
  const handleChange = (event) => {
    const value = event.target.value;
    setFormValues({
        ...formValues,
        [event.target.name]: value
    });  
  };

  function captchaChange(value) {
    setToken(value);
  };

  function sendEmail(e) {
    e.preventDefault();
    if (validate())
    {
        if (token) {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({"body":{"fullName":formValues.name,"email":formValues.email,"message":formValues.message,"source":"TAPS","token":token}}),
            };
            
            fetch(`https://ac6xrieiik.execute-api.us-east-2.amazonaws.com/default/bws-contact-form`, requestOptions)
            .catch(err => console.log(err));
            
            setFormValues({...formValues, name: '', email: '', message: ''});
        }
    }
  };

  return (
    <Grid container sx={{ padding: "50px 0", textAlign: "center", bgcolor: "secondary.main", color: "white" }}>
      <Container maxWidth="md">
        <Grid item xs={12} sx={{ marginBottom: "25px"}}>
          <Typography variant="h2">Have a question?</Typography>
        </Grid>
        <Box
          component="form"
          noValidate
          onSubmit={sendEmail}
        >
          <Grid item xs={12}>
            <TextField 
              label="Name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              fullWidth
              required
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
              required
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Message"
              name="message"
              value={formValues.message}
              onChange={handleChange}
              error={!!errors.message}
              helperText={errors.message}
              fullWidth
              required
              margin="normal"
              multiline
              minRows={5}
            />
          </Grid>
          <ReCAPTCHA 
            sitekey='6LcMs7gdAAAAAMcm4rAdZG5rBVb8CiqOLE3qqEXE'
            onChange={captchaChange}
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '25px'
            }}
          />
          <Button type="submit" variant="contained" sx={{ marginTop: "25px" }}>Submit</Button>
        </Box>
      </Container>
    </Grid>
  )
}

export default ContactForm;