import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1B75BB'
    },
    secondary: {
      main: '#95CBEE'
    },
    background: {
      main: '#1D1D1D'
    }
  },
  typography: {
    h1: { 
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    h2: { 
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 'bold',
          color: '#1B75BB'
        },
        secondary: {
          color: '#95CBEE'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'white',
          '&:hover fieldset': {
            borderColor: '#1B75BB !important'
          }
        },
        notchedOutline: {
          borderColor: 'white'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    }
  }
});

export default theme;