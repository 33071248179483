import { Box, Fab, useMediaQuery, useScrollTrigger, useTheme, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ScrollToTopButton() {
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector (
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: isDesktopScreen ? 64 : 16, right: isDesktopScreen ? 64 : 16, zIndex: '1000' }}
            >
                <Fab color="primary">
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Zoom>
    )
}