import { Link } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@mui/material';
import Restaurants from '../../assets/imgs/svgs/industries/restaurants.svg';
import Brewers from '../../assets/imgs/svgs/industries/brewers.svg';
import Growers from '../../assets/imgs/svgs/industries/growers.svg';
import Pools from '../../assets/imgs/svgs/industries/pools.svg';

const Industries = [
  {
    img: Restaurants,
    name: 'Restaurants',
    desc: 'We provide CO2, Nitrogen, and equipment sized to properly handle your needs.'
  },
  {
    img: Brewers,
    name: 'Brewers',
    desc: 'We provide CO2, Nitrogen, Oxygen, and equipment sized to meet your current and future needs.'
  },
  {
    img: Growers,
    name: 'Growers',
    desc: 'We provide CO2, Dry Ice, Alcohol, Butane and we lease or sell tanks to store CO2.'
  },
  {
    img: Pools,
    name: 'Pools',
    desc: 'We provide CO2 to keep water at a proper PH balance and we lease or sell tanks to store CO2. '
  }
];

function WeServe() {
  return (
    <Container>
      <Grid container sx={{ margin: '50px 0' }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h2" color="primary">We Serve</Typography>
        </Grid>
        <Grid container spacing={2} sx={{ padding: "50px 0" }}>
          {Industries.map((industry, i) => (
            <Grid item xs={12} sm={6} md={3} key={i} sx={{ textAlign: "center"}}>
              <img src={industry.img} alt={industry.name} style={{ height: "75px" }} />
              <Typography variant="h4" color="primary">{industry.name}</Typography>
              <Typography>{industry.desc}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button component={Link} to="/contact" variant="contained">Contact Us</Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default WeServe;